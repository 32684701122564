<template>
  <v-card color="background">
    <v-card-title>Add Bookmark</v-card-title>
    <v-card-text>
      <v-text-field
          v-model="title"
          label="Name"
          type="text"
          @keydown.enter="$refs.url.focus"
      />
      <v-text-field
          ref="url"
          v-model="url"
          label="URL"
          type="url"
          @keydown.enter="$refs.dir.focus"
      />
      <v-text-field
          ref="dir"
          v-model="directory"
          label="Directory (optional)"
          type="text"
          @keydown.enter="submit"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn depressed @click="$emit('cancel')">Cancel</v-btn>
      <v-spacer/>
      <v-btn color="primary" depressed @click="submit">Submit</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Append",
  props: {
    target: {
      type: Object,
      required: false,
      default: () => null,
    }
  },
  data: () => ({
    title: "",
    url: "",
    directory: "",
  }),
  methods: {
    async submit() {
      if (!(this.title && this.url)) return;
      const params = new URLSearchParams();
      params.set("title", this.title);
      params.set("url", this.url);
      params.set("directory", this.directory);
      const response = await this.$appClient.put("/pattern/bookmark", params);
      if (response.status === 201) {
        this.$emit("success");
      }
    },
  },
  created() {
    if (!this.target) return
    this.title = this.target.title
    this.url = this.target.url
    this.directory = this.target.directory
  }
};
</script>
