<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>Empty</v-list-item-title>
      <v-list-item-subtitle>There is noting.</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "EmptyItem",
};
</script>
