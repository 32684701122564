<template>
  <v-list-group no-action>
    <template #activator>
      <v-avatar>
        <v-icon>mdi-folder-outline</v-icon>
      </v-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <v-list-item
      v-for="bookmark in data"
      :key="bookmark.id"
      :href="!editing ? bookmark.url : null"
      :link="!editing"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{ bookmark.title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ bookmark.url }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-show="editing">
        <option-menu :target="bookmark" @reload="$emit('reload')" />
      </v-list-item-action>
    </v-list-item>
  </v-list-group>
</template>

<script>
import OptionMenu from "./BookmarkOptionMenu.vue";

export default {
  components: { OptionMenu },
  name: "Directory",
  props: {
    name: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    editing: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
