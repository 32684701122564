<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn title="Options" rounded v-bind="attrs" v-on="on">
        <v-icon>mdi-more</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-dialog v-model="overlay.delete">
        <template #activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-icon>
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Delete</v-list-item-content>
          </v-list-item>
        </template>
        <delete
          :target="target"
          @cancel="overlay.delete = false"
          @success="reload"
        />
      </v-dialog>
      <v-dialog v-model="overlay.modify">
        <template #activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-icon>
              <v-icon>mdi-pen</v-icon>
            </v-list-item-icon>
            <v-list-item-content>Modify</v-list-item-content>
          </v-list-item>
        </template>
        <append
          :target="target"
          @cancel="overlay.modify = false"
          @success="reload"
        />
      </v-dialog>
    </v-list>
  </v-menu>
</template>

<script>
import Append from "./BookmarkAppend.vue";
import Delete from "./BookmarkDelete.vue";

export default {
  name: "OptionMenu",
  components: {
    Append,
    Delete,
  },
  props: {
    target: {
      type: Object,
      required: true,
    },
  },
  methods: {
    reload() {
      this.overlay.delete = false;
      this.overlay.modify = false;
      this.$emit("reload");
    },
  },
  data: () => ({
    overlay: {
      delete: false,
      modify: false,
    },
  }),
};
</script>