<template>
  <v-card color="background">
    <v-card-title>Delete</v-card-title>
    <v-card-subtitle>&quot;{{ target.title }}&quot; will be deleted permanently.</v-card-subtitle>
    <v-card-actions>
      <v-btn depressed @click="$emit('cancel')">Cancel</v-btn>
      <v-spacer/>
      <v-btn class="red" dark depressed @click="submit">Delete</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Delete",
  props: {
    target: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  methods: {
    async submit() {
      if (!this.target.id) return;
      const response = await this.$appClient.delete(`/pattern/bookmark/${this.target.id}`);
      if (response.status === 200) {
        this.$emit('success')
      }
    }
  }
}
</script>
