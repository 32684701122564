<template>
  <v-list-item :href="!editing ? data.url : null" :link="!editing">
    <v-list-item-content>
      <v-list-item-title>
        {{ data.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ data.url }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-show="editing">
      <option-menu :target="data" @reload="$emit('reload')" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import OptionMenu from "./BookmarkOptionMenu.vue";

export default {
  components: { OptionMenu },
  name: "Item",
  props: {
    data: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
