<template>
  <v-card color="background" flat>
    <v-container>
      <v-card flat>
        <v-card-title>
          Bookmarks
          <v-spacer />
          <v-btn
            color="red"
            dark
            depressed
            rounded
            @click="filter.status = !filter.status"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-btn depressed rounded @click="editing = !editing">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle v-show="editing">Edit Mode</v-card-subtitle>
        <v-card-text>
          <v-card v-if="filter.status">
            <v-card-text>
              <v-text-field
                v-model="filter.content"
                clearable
                dense
                label="Search"
              />
            </v-card-text>
          </v-card>
          <v-card v-if="!loaded" flat>
            <v-card-text>
              <v-progress-circular color="primary" indeterminate />
            </v-card-text>
          </v-card>
          <v-list v-else>
            <empty-item v-if="loaded && !bookmarks.length" @reload="reload" />
            <div v-for="(data, name) in exportData" :key="name">
              <directory
                v-if="Array.isArray(data)"
                :name="name"
                :data="data"
                :editing="editing"
                @reload="reload"
              />
              <item v-else :data="data" :editing="editing" />
            </div>
          </v-list>
        </v-card-text>
      </v-card>
    </v-container>
    <v-fab-transition>
      <v-dialog v-model="append">
        <template #activator="{ on, attrs }">
          <v-btn
            v-show="editing"
            bottom
            color="amber"
            fab
            fixed
            left
            @click="append = true"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <append @cancel="append = false" @success="reload" />
      </v-dialog>
    </v-fab-transition>
  </v-card>
</template>

<script>
import { sha3_256, sha3_512 } from "js-sha3";
import aes from "aes-js";
import { jwtDecode } from "jwt-decode";

import {
    getPassport,
} from '../plugins/shiranui';

import Append from "@/components/BookmarkAppend";
import EmptyItem from "../components/BookmarkEmptyItem.vue";
import Directory from "../components/BookmarkDirectory.vue";
import Item from "../components/BookmarkItem.vue";

const DEV_MODE = process.env.NODE_ENV === "development";

export default {
  name: "Bookmarks",
  components: { Append, EmptyItem, Directory, Item },
  data: () => ({
    loaded: false,
    editing: false,
    current: null,
    append: false,
    filter: {
      status: false,
      content: null,
    },
    bookmarks: [],
    signal: "",
  }),
  computed: {
    exportData() {
      if (this.filter.status) {
        return this.bookmarks.filter(
          (i) =>
            i.title.includes(this.filter.content) ||
            i.url.includes(this.filter.content)
        );
      } else {
        return this.bookmarks.reduce(function (result, item) {
          if (item.directory === "") {
            result[item.title] = item;
          } else {
            if (Array.isArray(result[item.directory])) {
              result[item.directory].push(item);
            } else {
              result[item.directory] = [item];
            }
          }
          return result;
        }, {});
      }
    },
  },
  methods: {
    reload() {
      localStorage.removeItem("bookmarks");
      this.append = false;
      this.load();
    },
    async load() {
      this.loaded = false;
      if (!this.loadLocal()) {
        if (await this.loadOnline()) {
          this.saveLocal();
        }
      }
      this.loaded = true;
    },
    async checkCacheHash(hash) {
      const response = await this.$appClient.head("/pattern/bookmarks", {
        params: { hash },
      });
      if (response.status === 200) this.reload();
    },
    loadLocal() {
      if (!window.crypto) return false;
      const rawData = localStorage.getItem("bookmarks");
      if (!rawData) return false;
      const [dataHash, encryptedHex, encodedIV] = rawData.split(".");
      this.checkCacheHash(dataHash);
      const secret = this.getSecret();
      if (!secret.length) return false;
      const iv = this.importIV(encodedIV);
      try {
        this.bookmarks = this.decrypt(secret, iv, encryptedHex);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    saveLocal() {
      if (!window.crypto) return false;
      const secret = this.getSecret();
      if (!secret.length) return false;
      const iv = this.generateIV();
      const dataHash = this.computeDataHash(this.bookmarks);
      const encryptedHex = this.encrypt(secret, iv, this.bookmarks);
      const encodedIV = this.exportIV(iv);
      localStorage.setItem(
        "bookmarks",
        [dataHash, encryptedHex, encodedIV].join(".")
      );
      return true;
    },
    generateIV() {
      const iv = new Uint8Array(16);
      window.crypto.getRandomValues(iv);
      return iv;
    },
    getSecret() {
      const signal = DEV_MODE ? "signal" : this.signal;
      if (!signal.length) return [];
      return Array.from(sha3_512(`bookmark_${signal}`))
        .map((s) => s.charCodeAt(0))
        .slice(0, 32);
    },
    computeDataHash(data) {
      return sha3_256(JSON.stringify(data));
    },
    importIV(encodedIV) {
      return aes.utils.hex.toBytes(encodedIV);
    },
    exportIV(originalIV) {
      return aes.utils.hex.fromBytes(originalIV);
    },
    encrypt(secret, iv, data) {
      const textBytes = aes.utils.utf8.toBytes(JSON.stringify(data));
      const textBytesPadding = aes.padding.pkcs7.pad(textBytes);
      const aesCbc = new aes.ModeOfOperation.cbc(secret, iv);
      const encryptedBytes = aesCbc.encrypt(textBytesPadding);
      return aes.utils.hex.fromBytes(encryptedBytes);
    },
    decrypt(secret, iv, text) {
      const hexBytes = aes.utils.hex.toBytes(text);
      const aesCbc = new aes.ModeOfOperation.cbc(secret, iv);
      const textBytesPadding = aesCbc.decrypt(hexBytes);
      const textBytes = aes.padding.pkcs7.strip(textBytesPadding);
      return JSON.parse(aes.utils.utf8.fromBytes(textBytes));
    },
    async loadOnline() {
      const response = await this.$appClient.get("/pattern/bookmarks");
      if (response.status === 200) this.bookmarks = response.data;
      return true;
    },
  },
  async created() {
    const {passport} = await getPassport();
    const {jti} = jwtDecode(passport);
    this.signal = jti;
    this.load();
  },
};
</script>
